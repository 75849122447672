// Bootstrap vars
$enable-flex: true;
$enable-grid-classes: false !default; // Don't generate the row, col-x, etc classes

@import './variables';
@import './colours';
@import './fonts';
@import './mixins';

// Tipple Partner Panel styles
body {
    background: $colour-white;
    font-family: 'Greycliff', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include font-greycliff;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 700;
    line-height: 1.1;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 22px;
}

#app-root,
.app-container {
    // display: flex;
    // height: 100%;
}

:global {
    .modal-backdrop {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1040;
        background-color: #000;
    }
    .modal-backdrop.fade {
        opacity: 0;
    }
    .modal-backdrop.show {
        opacity: 0.5;
    }
}
