@import 'scss/_colours';

$nav-header-height: 100px;

.layout {
    display: flex;
    flex: 1;
    flex-direction: row;
}

.main {
    background: $colour-panel-body-bg;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    height: 100vh;
}

.content {
    display: flex;
    flex-direction: column;
    flex: auto;
    overflow: auto;
}