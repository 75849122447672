@import 'scss/_variables.scss';
@import 'scss/_colours.scss';
@import 'scss/_reset.scss';
@import 'scss/_mixins.scss';

// .app-container {
//     display: flex;
//     height: 100%;
// }

// .page {
//     display: flex;
//     flex-direction: column;
//     height: 100%;
//     padding: #{$padding-base * 3} #{$padding-base * 2} #{$padding-base * 2};
//     width: 100%;
// }

h4 {
    @include text-h4;
}

// h1,
// h2,
// h3,
// h4,
// h5 {
//     color: $colour-text-headings;
//     font-family: 'Greycliff';
//     font-weight: 700;
//     line-height: 1.1;

//     .fa {
//         margin: 0 #{$padding-base * 1.5} 0 #{$padding-base * .5};
//     }
// }

// h1 {
//     font-size: 29px;
//     margin-bottom: 16px;
// }

// h2 {
//     font-size: 24px;
//     margin-bottom: #{$padding-base * 3};
//     margin-top: 0;
// }

// h3 {
//     @include text-h3;
// }

// h4 {
//     @include text-h4;
// }

// .side-panel-backdrop {
//     background-color: rgba(66, 90, 112, 0.81);
//     position: fixed;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     z-index: 1040;

//     &.fade {
//         opacity: 0;
//     }

//     &.show {
//         opacity: 1;
//     }
// }

// .card-container {
//     display: flex;
//     flex-direction: row;
//     height: 100%;
//     padding: 0;

//     .card {
//         &:first-of-type {
//             margin-left: 0;
//         }

//         &:last-of-type {
//             margin-right: 0;
//         }
//     }
// }

// .context-menu {
//     background: $colour-white;
//     border-radius: 5px;
//     box-shadow: 0 0 1px 0 rgba(67, 90, 111, 0.47), 0 3px 6px 0 rgba(67, 90, 111, 0.3);
//     color: $colour-text;
//     cursor: pointer;
//     list-style: none;
//     margin: 0;
//     padding: 0;
//     position: relative;
//     width: 180px;
//     z-index: 5000;
// }

// .context-menu-item {
//     align-items: center;
//     display: flex;
//     flex-direction: row;
//     font-family: 'SF UI Text Regular';
//     font-size: 14px;
//     line-height: 1.4;
//     padding: 10px 12px;
//     text-align: left;

//     &:hover {
//         background: $colour-menu-active;
//     }

//     &.context-menu-item-destructive {
//         border-top: 1px solid #edf0f2;
//         color: $colour-error-indicator;

//         &:hover {
//             background: rgba(236, 76, 71, 0.2);
//         }
//     }

//     > img {
//         margin-right: $padding-base;
//     }

//     .fa {
//         margin-right: $padding-base;
//     }
// }

// .text-small {
//     font-size: 10px;
// }
