// Bootstrap mixins

@import 'bootstrap/functions';
@import 'bootstrap/variables';

// Utilities
@import 'bootstrap/mixins/breakpoints';
// @import "bootstrap/mixins/hover";
// @import "bootstrap/mixins/image";
// @import "bootstrap/mixins/badge";
// @import "bootstrap/mixins/resize";
// @import "bootstrap/mixins/screen-reader";
// @import "bootstrap/mixins/size";
// @import "bootstrap/mixins/reset-text";
// @import "bootstrap/mixins/text-emphasis";
// @import "bootstrap/mixins/text-hide";
// @import "bootstrap/mixins/text-truncate";
// @import "bootstrap/mixins/visibility";

// // Layout
@import 'bootstrap/mixins/clearfix';
// @import "mixins/navbar-align";
@import 'bootstrap/mixins/grid-framework';
@import 'bootstrap/mixins/grid';
@import 'bootstrap/mixins/float';

@import 'bootstrap/mixins/gradients';
@import 'bootstrap/mixins/box-shadow';
@import 'bootstrap/mixins/transition';
@import 'bootstrap/mixins/hover';
@import 'bootstrap/mixins/buttons';

// Tipple mixins

@import 'forms';

// Mixins

@mixin shadow-cta() {
    box-shadow: 5px 10px 12px rgba(0, 0, 0, 0.03);
}

// Including element requires position: relative
@mixin shadow-hover() {
    &::before {
        @include shadow-cta;
        content: '';
        display: block;
        height: 100%;
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        transition: opacity 0.3s ease-in-out;
        width: 100%;
    }

    &:hover {
        &::before {
            opacity: 1;
        }
    }
}

@mixin text-tiny() {
    font-size: 10px;
    line-height: 1.1;
}

@mixin text-small() {
    font-size: 12px;
    line-height: 1.2;
}

@mixin text-small-caps() {
    font-size: 11px;
    line-height: 1.45;
    text-transform: uppercase;
}

@mixin text-medium() {
    font-size: 16px;
    line-height: 1.2;
}

@mixin text-large() {
    font-size: 20px;
    line-height: 1.2;
}

@mixin font-greycliff() {
    font-family: 'Greycliff';
}

@mixin font-sf-pro-text-light() {
    font-family: 'SF UI Text Light';
    font-weight: 300;
}

@mixin font-sf-pro-text() {
    font-family: 'SF UI Text Regular';
    font-weight: 400;
}

@mixin font-sf-pro-text-medium() {
    font-family: 'SF UI Text Medium';
    font-weight: 500;
}

@mixin font-sf-pro-text-semibold() {
    font-family: 'SF UI Text Semibold';
    font-weight: 600;
}

@mixin font-sf-mono() {
    font-family: 'SF Mono Bold';
    font-weight: normal;
}

@mixin font-helvetica() {
    font-family: 'Helvetica';
    font-weight: 400;
}

@mixin font-default() {
    @include font-greycliff;
}

@mixin text-subtitle() {
    font-family: 'SF UI Text Medium'; // FIXME: Add to mixin / typography
    font-weight: 500;
    font-size: 9px;
    line-height: 1.2;
}

@mixin font-ui-medium() {
    font-family: 'SF UI Text Medium';
    font-weight: 500;
}

@mixin font-ui-semibold() {
    font-family: 'SF UI Text Semibold';
    font-weight: 600;
}

@mixin font-ui-bold() {
    font-family: 'SF UI Text Bold';
    font-weight: 700;
}

@mixin text-h3() {
    font-family: 'SF UI Text Semibold';
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
}

@mixin text-h4() {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.3;
}

@mixin text-overflow-ellipsis() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// Form layout and fields

@mixin form-fieldset() {
    align-items: top;
    background: $colour-white;
    border: 0;
    box-shadow: inset 0px -1px 0px #edf0f2;
    display: flex;
    flex-direction: row;
    padding: $padding-base;
}

@mixin form-label() {
    font-family: 'Helvetica';
    font-size: 12px;
    font-weight: 600;
    display: inline-flex;
    flex: 0 1 30%;
    line-height: 1.3;
    padding-left: 3px;
}

@mixin form-input-container() {
    display: flex;
    flex: 1 1 70%;
    flex-direction: column;
}

@mixin form-input-inline() {
    border: 1px solid #c3cad0;
    box-shadow: inset 0px 1px 2px rgba(67, 90, 111, 0.2);
    border-radius: 3px;
    color: $colour-select-text;
    padding: 5px 6px;

    &.has-error {
        box-shadow: inset 0 0 0 1px $colour-red-base, inset 0 1px 2px rgba(67, 90, 111, 0.14);
    }
}

@mixin form-input() {
    @include form-input-inline;
    flex: 1 1 70%;
}

@mixin form-error() {
    @include text-medium;
    color: $colour-red-base;
    padding: 3px 0 0 2px;
}

@mixin form-textarea() {
    border: 1px solid #c3cad0;
    box-shadow: inset 0px 1px 2px rgba(67, 90, 111, 0.2);
    border-radius: 3px;
    color: $colour-select-text;
    flex: 1 1 70%;
    padding: 9px 12px;
}

// Modal

@mixin modal() {
    animation: fadeIn 0.2s ease-in-out;
    bottom: 0;
    left: 0;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1050;

    .dialog {
        position: relative;
        margin: 100px auto auto;
        pointer-events: none;
        width: 870px;
    }

    .content {
        border: 1px solid #e3e7eb;
        border-radius: 5px;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: #fff;
        border-radius: 0.3rem;
        outline: 0;
    }

    .header {
        align-items: flex-start;
        background: $colour-white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: #{$padding-base * 2};
        position: relative;

        h4 {
            color: #234361;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            letter-spacing: -0.1px;
            line-height: 1.2;
            margin-bottom: 0;
        }

        .closeButton {
            color: $colour-grey-base;
            line-height: 1;
            position: absolute;
            right: #{$padding-base * 2-10};
            top: 4px;
            padding: 10px;
        }
    }
}

@mixin modal-backdrop() {
    background-color: #000;
    bottom: 0;
    left: 0;
    opacity: 0.5;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1040;
}

// Card
@mixin card() {
    align-items: center;
    background-color: #f5f5f5;
    border: 1px solid #c4c9cd;
    box-shadow: 0px 2px 4px rgba(133, 144, 163, 0.1);
    border-radius: 6px;
    color: $colour-text-headings;
    display: flex;
    padding: 6px 16px;
    position: relative;

    &.disabled {
        > * {
            opacity: 0.5;
        }
    }
}

// Panel styles
@mixin panel-wrapper() {
    border: 1px solid #e3e7eb;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    outline: 0;
    pointer-events: auto;
    width: 100%;
}

@mixin panel-head() {
    background-color: $colour-white;
    box-shadow: 0px 0px 1px rgba(67, 90, 111, 0.47);
    z-index: 2;
}

@mixin panel-body() {
    display: flex;
    flex: 1;
    flex-direction: column;
}

@mixin panel-content() {
    flex: 1;
    overflow-y: scroll;
}

@mixin panel-footer() {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.0001), rgba(0, 0, 0, 0.0001)), #ffffff;
    box-shadow: inset 0px 1px 0px #e4e7eb;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: #{$padding-base * 2};
}

// Side panel styles
@mixin side-panel($pseudo-overlay: false) {
    animation: slideInLeft 0.2s forwards;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    background: $colour-panel-body-bg;
    flex-basis: auto;
    flex-flow: column;
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    height: calc(100vh);
    overflow-y: visible;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 3;
    width: 576px;

    @if ($pseudo-overlay) {
        &::before {
            @include side-panel-overlay;
        }
    }
}

@mixin side-panel-overlay() {
    animation: fadeIn 0.2s forwards;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    background: rgba(66, 90, 112, 0.81);
    content: '';
    display: block;
    height: 100%;
    opacity: 1;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 2;
}

@mixin side-panel-header() {
    background: $colour-white;
    border-bottom: 1px solid $colour-grey-light;
    padding: $padding-panel;

    h1 {
        color: $colour-text-headings;
        font-size: 16px;
        line-height: 1.2;
        margin-bottom: 0;
    }

    h2 {
        color: $colour-text;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.2;
        margin-bottom: 6px;
    }
}

@mixin side-panel-body() {
    background: $colour-panel-body-bg;
    flex: 1;
    padding: #{$padding-base * 2} #{$padding-base * 2} 100px;
}

@mixin side-panel-footer() {
    background: $colour-white;
    border-top: 1px solid $colour-grey-light;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    padding: #{$padding-base * 2};
    position: absolute;
    width: 100%;
}

@mixin side-panel-tabs() {
    background: $colour-white;
}

// Pane with curved borders that sits inside other content areas
@mixin pane() {
    background: $colour-white;
    border: 1px solid $colour-grey-light;
    border-radius: 5px;
}

// Buttons

@mixin btn() {
    @include font-sf-pro-text-medium;
    @include text-large;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    background-color: transparent;
    color: #212529;
    display: inline-block;
    user-select: none;
    border-radius: 6px;
    min-height: 39px;
    min-width: 100px;
    padding: 9px 30px;
    position: relative;
    text-align: center;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    vertical-align: middle;

    img {
        left: calc((100% - 18px) / 2);
        position: absolute;
        top: 10px;
    }
}

@mixin btn-cta() {
    @include font-greycliff;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: $btn-border-width solid transparent;
    @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-line-height, $btn-border-radius);
    @include transition($btn-transition);
    border-radius: 6px;

    // Share hover and focus styles
    @include hover-focus {
        text-decoration: none;
    }

    &:focus,
    &.focus {
        outline: 0;
        box-shadow: $btn-focus-box-shadow;
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
        opacity: $btn-disabled-opacity;
        @include box-shadow(none);
    }

    // Opinionated: add "hand" cursor to non-disabled .btn elements
    &:not(:disabled):not(.disabled) {
        cursor: pointer;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        background-image: none;
        @include box-shadow($btn-active-box-shadow);

        &:focus {
            @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
        }
    }
}

@mixin tooltip-override {
    font-size: 12px !important;
    line-height: 1.2 !important;
    background: rgba(66, 90, 112, 0.7) !important;
    color: $colour-white !important;
    z-index: 1099;

    &::after {
        border-bottom-color: rgba(66, 90, 112, 0.7) !important;
    }
}

@mixin icon-circle {
    align-items: center;
    align-content: center;
    background: $colour-grey-icon-bg;
    border-radius: 100%;
    display: flex;
    height: 32px;
    justify-content: center;
    width: 32px;

    img {
        width: 18px;
    }
}

@mixin icon-orders {
    align-content: center;
    background: $colour-grey-icon-bg;
    border-radius: 100%;
    display: flex;
    height: 32px;
    justify-content: center;
    width: 32px;

    img {
        width: 18px;
    }
}

@mixin icon-drivers {
    @include icon-circle;
}

@mixin map-filters {
    align-items: center;
    bottom: 40px;
    display: inline-flex;
    left: 20px;
    position: absolute;

    > * {
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }

    label {
        color: $colour-text;
    }
}

@mixin filter-toggle {
    align-items: center;
    background: $colour-white;
    border-radius: 3px;
    display: inline-flex;
    padding: 4px 6px 4px 8px;
}

@mixin select-menu-button() {
    @include font-sf-pro-text;
    @include text-medium;
    align-items: center;
    background: linear-gradient(360deg, #f4f5f7 0%, #ffffff 100%), #ffffff;
    box-shadow: inset 0px -1px 1px rgba(67, 90, 111, 0.09);
    border: 1px solid #d4dae0;
    border-radius: 3px;
    color: $colour-filter-text;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 110px;

    &:hover {
        background: darken(rgba(16, 112, 202, 0.08), 15%);
    }

    &.is-expanded {
        background: rgba(16, 112, 202, 0.08);
        box-shadow: inset 0px 1px 1px rgba(67, 90, 111, 0.09);
    }
}

@mixin select-menu-options() {
    background: $colour-white;
    border-radius: 5px;
    box-shadow: 0 0 1px 0 rgba(67, 90, 111, 0.47), 0 3px 6px 0 rgba(67, 90, 111, 0.3);
    color: $colour-select-text;
    cursor: pointer;
    // position: absolute;
    right: 0;
    top: calc(100% + 4px);
    width: 280px;
    z-index: 5;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}

@mixin input-search() {
    background: $colour-white;
    border: 1px solid #c3cad1;
    box-sizing: border-box;
    box-shadow: inset 0px 1px 2px rgba(67, 90, 111, 0.2);
    border-radius: 3px;
}

@mixin order-list() {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // justify-content: space-between;

    // &::after {
    //     content: '';
    //     flex: auto;
    // }
}

@mixin hover-darken($base-colour) {
    &.is-active,
    &:hover {
        background-color: darken($base-colour, 5%);
        border-color: darken($base-colour, 5%);
    }
}

// Blue (primary)
@mixin colouring-blue-base() {
    background-color: $colour-blue-light;
    border-color: $colour-blue-light;
    color: $colour-blue-dark;
}

@mixin colouring-blue-dark() {
    background-color: $colour-blue-base;
    border-color: $colour-blue-base;
    color: $colour-blue-light;
}

@mixin colouring-blue-light() {
    background-color: $colour-white;
    border-color: $colour-blue-dark;
    color: $colour-blue-dark;
}

// Green (success)
@mixin colouring-green-base() {
    background-color: $colour-green-light;
    border-color: $colour-green-light;
    color: $colour-green-dark;

    @include hover-darken($colour-green-light);
}

@mixin colouring-green-dark() {
    background-color: $colour-green-base;
    border-color: $colour-green-base;
    color: $colour-green-light;
}

@mixin colouring-green-darker() {
    background-color: darken($colour-green-base, 10%);
    border-color: darken($colour-green-base, 10%);
    color: $colour-green-light;
}

@mixin colouring-green-light() {
    background-color: $colour-white;
    border-color: $colour-green-dark;
    color: $colour-green-dark;
}

// Red (error)
@mixin colouring-red-base() {
    background-color: $colour-red-light;
    border-color: $colour-red-light;
    color: $colour-red-dark;

    @include hover-darken($colour-red-light);
}

@mixin colouring-red-dark() {
    background-color: $colour-red-dark;
    border-color: $colour-red-dark;
    color: $colour-red-light;
}

@mixin colouring-red-light() {
    background-color: $colour-white;
    border-color: $colour-red-dark;
    color: $colour-red-dark;
}

// Orange (warning)
@mixin colouring-orange-base() {
    background-color: $colour-orange-light;
    border-color: $colour-orange-light;
    color: $colour-orange-dark;
}

@mixin colouring-orange-dark() {
    background-color: $colour-orange-base;
    border-color: $colour-orange-base;
    color: $colour-orange-light;
}

@mixin colouring-orange-light() {
    background-color: $colour-white;
    border-color: $colour-orange-dark;
    color: $colour-orange-dark;
}

@mixin pill() {
    background-color: $colour-grey-light;
    border-color: $colour-grey-light;
    border-width: 2px;
    border-style: solid;
    border-radius: 6px;
    color: $colour-grey-base;
    font-family: 'SF Mono Bold';
    font-weight: normal;
    padding: 0;
    position: relative;
    text-align: center;
}

@mixin page-filters() {
    display: flex;
    flex-direction: row;
    position: absolute;
    right: #{$padding-base * 3};
    top: #{$padding-base * 3};
}

@mixin transparent-button() {
    appearance: none;
    border: none;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
}
