// Tipple colour stuff

$colour-spritz: #f57153;
$colour-brut: #faf7f2;

$colour-success: #61b853;

$colour-text-body: #262626;

$colour-filter-label: #757575;

// Product
$colour-price: #757575;

// $colour-button-disabled: #929292;

$colour-primary: $colour-spritz !default;
$colour-info: #e8ae49;
$colour-disabled-label: #e0e0e0;

// Theme colours for bootstrap buttons
$theme-colors: () !default;
$theme-colors: map-merge(
    (
        'primary': $colour-primary,
        // 'secondary': $secondary,
            // 'success': $success,
            // 'info': $info,
            // 'warning': $warning,
            // 'danger': $danger,
            // 'light': $light,
            // 'dark': $dark
    ),
    $theme-colors
);

// Base colour scheme - admin / evergreen
$colour-red-base: #ec4c47;
$colour-red-dark: #bf0e08;
$colour-red-light: #fae2e2;
$colour-red-lightest: #fef6f6;

$colour-orange-base: #d9822b;
$colour-orange-dark: #95591e;
$colour-orange-light: #fae3cd;
$colour-orange-lightest: #fdf8f3;

$colour-yellow-base: #f7d154;
$colour-yellow-dark: #7e6514;
$colour-yellow-light: #fbe6a2;
$colour-yellow-lightest: #fef8e7;

$colour-green-base: #47b881;
$colour-green-dark: #00783e;
$colour-green-light: #d4eee2;
$colour-green-lightest: #f1faf5;

$colour-teal-base: #14b5d0;
$colour-teal-dark: #007489;
$colour-teal-light: #d2eef3;
$colour-teal-lightest: #f1fbfc;

$colour-blue-base: #1070ca;
$colour-blue-dark: #084b8a;
$colour-blue-light: #dbeaf7;
$colour-blue-lightest: #f6f9fd;

$colour-purple-base: #735dd0;
$colour-purple-dark: #37248f;
$colour-purple-light: #eae7f8;
$colour-purple-lightest: #f8f7fc;

$colour-grey-base: #66788a;
$colour-grey-dark: darken($colour-grey-base, 10%);
$colour-grey-light: #e4e7eb;
$colour-grey-lightest: #fafafa;

$colour-grey-icon-bg: #edf0f2;
$colour-grey-inset-shadow: $colour-grey-icon-bg;

$colour-select-text: #435a6f;
$colour-select-hover-bg: #ecf4fb;

$colour-text: #425a70;
$colour-text-light: #435a6f;
$colour-text-headings: #234361;
$colour-body-bg: #eaeeef;

$colour-button-disabled: #4c5d6e;

$colour-panel-header-bg: $colour-grey-lightest;
$colour-panel-body-bg: #f5f6f7;

$colour-button-default: $colour-primary;

$colour-filter-text: #4c5d6e;

$colour-white: #fff;

$colour-error-text: #bf0e08;
$colour-error-bg: #fae2e2;
$colour-error-indicator: $colour-red-base;

$colour-warning-text: #95591e;
$colour-warning-bg: #fae3cd;
$colour-warning-indicator: $colour-orange-base;

$colour-default-text: $colour-text;
$colour-default-bg: #e4e7eb;
$colour-default-indicator: $colour-grey-base;

$colour-success-text: #00783e;
$colour-success-bg: #d4eee2;
$colour-success-indicator: $colour-green-base;
$colour-success-action: darken($colour-success-bg, 5%);

$colour-primary-text: $colour-blue-base;
$colour-primary-bg: #ecf4fb;
$colour-primary-indicator: $colour-blue-base;
$colour-primary-bg-button: linear-gradient(360deg, #116ab8 0%, #0788de 100%);

$colour-run-droppable: #edecec;
$colour-run-border-droppable: #a2a4a6;

$colour-panel-header-bg: $colour-grey-lightest;

// Old colours from admin - refactor?

$colour-white: #fff;
$colour-title: #434349;
$colour-deemphasize: #959cb6;

// $colour-primary: #3d8bd4;
// $colour-text: #222;
$colour-text-headings: #234361;

$colour-blue: #1070ca;

$colour-bluegrey-dark: rgba(67, 90, 111, 1);
$colour-bluegrey-light: #425a70;
$colour-bluegrey-lighter: rgba(67, 90, 111, 0.47);
$colour-bluegrey-lightest: rgba(67, 90, 111, 0.3);
$colour-form-grey: #f5f6f7;

// $colour-grey-lightest: rgba(0, 0, 0, .12);
// $colour-grey-light: rgba(0, 0, 0, .38);

$colour-link: $colour-blue;

$colour-error: #bf0e08;

$colour-disabled-bg: #f5f6f7;
$colour-disabled-text: rgba(67, 90, 111, 0.47);
$colour-disabled-border: $colour-bluegrey-lightest;

$colour-tag-light: #e0e0e0;

$colour-modify-header: #234361;

$colour-input-text: $colour-bluegrey-dark;
$colour-input-border: #c3cad1;
$colour-form-label: $colour-bluegrey-light;

$colour-input-disabled: #999;
$colour-input-disabled-border: #e6e6e6;
$colour-input-disabled-bg: #f2f2f2;

$colour-overlay: rgba(66, 90, 112, 0.81);

$colour-white-o40: rgba(255, 255, 255, 0.4);

$colour-toggle-primary: #3d8bd4;
$colour-toggle-default: #dadfe3;

$colour-menu-active: rgba(16, 112, 202, 0.08);

// Navigation layout
$colour-nav-border: #edf0f2;
$colour-nav-link: #757575;
