@import 'scss/_colours.scss';

$sidebar-width: 96px;
$nav-height: 116px;

$colour-nav-active-bg: rgba(245, 113, 83, 0.04);

.sidebar {
    background: $colour-white;
    border-right: 1px solid $colour-nav-border;
    display: flex;
    flex-direction: column;
    width: $sidebar-width;
}

.brand {
    align-items: center;
    border-bottom: 1px solid $colour-nav-border;
    display: flex;
    flex-direction: column;
    height: $nav-height;
    justify-content: center;
}

.nav {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.list {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
}

.item {
    border-bottom: 1px solid $colour-nav-border;
    color: $colour-nav-link;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    // TODO: Make these a mixin somewhere
    font-size: 12px;
    font-weight: 600;
    align-items: center;
    padding: 17px 3px 11px;
    text-decoration: none;

    &.active,
    &:hover {
        background: linear-gradient(0deg, $colour-nav-active-bg, $colour-nav-active-bg), $colour-white;
    }

    &.active {
        color: $colour-spritz;
    }

    &.end {
        border-bottom: 0;
        border-top: 1px solid $colour-nav-border;
    }

    img {
        height: 32px;
        margin-bottom: 5px;
    }
}