$gc-font-path: '/assets/fonts/greycliff/fonts';

@font-face {
    font-family: 'Greycliff';
    src: url('#{$gc-font-path}/regular/greycliff-cf-regular.eot?#iefix') format('embedded-opentype'),
        url('#{$gc-font-path}/regular/greycliff-cf-regular.woff') format('woff'),
        url('#{$gc-font-path}/regular/greycliff-cf-regular.ttf') format('truetype'),
        url('#{$gc-font-path}/regular/greycliff-cf-regular.svg#Greycliff') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Greycliff';
    src: url('#{$gc-font-path}/medium/greycliff-cf-medium.eot?#iefix') format('embedded-opentype'),
        url('#{$gc-font-path}/medium/greycliff-cf-medium.woff') format('woff'),
        url('#{$gc-font-path}/medium/greycliff-cf-medium.ttf') format('truetype'),
        url('#{$gc-font-path}/medium/greycliff-cf-medium.svg#Greycliff') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Greycliff';
    src: url('#{$gc-font-path}/demi-bold/greycliff-cf-demi-bold.eot?#iefix') format('embedded-opentype'),
        url('#{$gc-font-path}/demi-bold/greycliff-cf-demi-bold.woff') format('woff'),
        url('#{$gc-font-path}/demi-bold/greycliff-cf-demi-bold.ttf') format('truetype'),
        url('#{$gc-font-path}/demi-bold/greycliff-cf-demi-bold.svg#Greycliff') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Greycliff';
    src: url('#{$gc-font-path}/bold/greycliff-cf-bold.eot?#iefix') format('embedded-opentype'),
        url('#{$gc-font-path}/bold/greycliff-cf-bold.woff') format('woff'),
        url('#{$gc-font-path}/bold/greycliff-cf-bold.ttf') format('truetype'),
        url('#{$gc-font-path}/bold/greycliff-cf-bold.svg#Greycliff') format('svg');
    font-weight: bold;
    font-style: normal;
}
